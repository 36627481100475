// src/utils/convenienceFeeCalculator.js

const CONVENIENCE_FEE_RATE = 0.05; // Assume 5% convenience fee rate

// Function to calculate convenience fee for a given amount
function calculateConvenienceFee(amount) {
  return amount * CONVENIENCE_FEE_RATE;
}

// Export the functions
export { calculateConvenienceFee };