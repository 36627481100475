import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Table, Button, InputGroup, FormControl } from 'react-bootstrap';
import * as XLSX from 'xlsx'; // Import XLSX library for Excel export
import { API_ENDPOINTS } from '../config';

const OrganizedEventsTable = ({ organizedEvents, handleEditEvent, handleDeleteEvent }) => {
  const [purchasedTickets, setPurchasedTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [summary, setSummary] = useState({
    totalPurchases: 0,
    totalAmountPaid: 0,
  });

  const fetchPurchasedTickets = async (eventId) => {
    setLoading(true);
    setError(null);

    try {
      //const response = await fetch(`http://localhost:3010/api1/events/${eventId}/purchased-tickets`);
      console.log('eventId',eventId)
      console.log('API_ENDPOINTS',API_ENDPOINTS)
      console.log('API_ENDPOINTS.getEventPurchasedTickets(eventId)',API_ENDPOINTS.getEventPurchasedTickets(eventId))
      const response = await fetch(API_ENDPOINTS.getEventPurchasedTickets(eventId));

      if (!response.ok) {
        throw new Error('Failed to fetch purchased tickets');
      }

      const data = await response.json();
      setPurchasedTickets(data.purchasedTickets);
      calculateSummary(data.purchasedTickets);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSendComplimentaryTicket = async (eventId) => {
    try {
      const response = await fetch(API_ENDPOINTS.sendComplimentaryTicket(eventId), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ /* Optional data for the ticket */ }),
      });
      if (response.ok) {
        alert('Complimentary ticket sent successfully!');
      } else {
        console.error('Failed to send complimentary ticket.');
      }
    } catch (error) {
      console.error('Error sending complimentary ticket:', error);
    }
  };

  const calculateSummary = (tickets, totalCapacity = 0) => {
    const totalPurchases = tickets.length;
    const totalAmountPaid = tickets.reduce((sum, ticket) => sum + parseFloat(ticket.TotalAmount), 0);
    const totalTicketsSold = tickets.reduce((sum, ticket) => sum + parseInt(ticket.Quantity, 10), 0);
    const averageTicketPrice = totalTicketsSold > 0 ? (totalAmountPaid / totalTicketsSold).toFixed(2) : 0;
    
    const highestPurchase = Math.max(...tickets.map(ticket => parseFloat(ticket.TotalAmount)));
    const lowestPurchase = Math.min(...tickets.map(ticket => parseFloat(ticket.TotalAmount)));
    
    const ticketTypeCounts = tickets.reduce((counts, ticket) => {
      counts[ticket.Type] = (counts[ticket.Type] || 0) + ticket.Quantity;
      return counts;
    }, {});
    const mostPopularType = Object.keys(ticketTypeCounts).reduce((a, b) => ticketTypeCounts[a] > ticketTypeCounts[b] ? a : b);
    
    const percentageSold = totalCapacity > 0 ? ((totalTicketsSold / totalCapacity) * 100).toFixed(2) : null;
    
    setSummary({
      totalPurchases,
      totalAmountPaid: totalAmountPaid.toFixed(2),
      totalTicketsSold,
      averageTicketPrice,
      highestPurchase,
      lowestPurchase,
      mostPopularType,
      percentageSold,
    });
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value.toLowerCase());
  };

  const printTickets = () => {
    window.print();
  };

  const downloadAsCSV = () => {
    const csvRows = [
      ['First Name', 'Last Name', 'Email', 'Phone Number', 'Ticket Type', 'Quantity', 'Total Amount'],
      ...purchasedTickets.map(ticket => [
        ticket.FirstName,
        ticket.LastName,
        ticket.Email,
        ticket.PhoneNumber,
        ticket.TicketType,
        ticket.Quantity,
        ticket.TotalAmount
      ])
    ];

    const csvContent = `data:text/csv;charset=utf-8,${csvRows.map(e => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'purchased_tickets.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(purchasedTickets);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Purchased Tickets');
    XLSX.writeFile(workbook, 'purchased_tickets.xlsx');
  };

  const filteredTickets = purchasedTickets.filter(ticket =>
    ticket.FirstName.toLowerCase().includes(filter) || 
    ticket.LastName.toLowerCase().includes(filter) || 
    ticket.Email.toLowerCase().includes(filter) || 
    ticket.PhoneNumber.toLowerCase().includes(filter)
  );

  return (
    <div className="table-responsive">
    <Table striped bordered hover className="table-fluid mb-4">
    <thead className="bg-light table-active">
    <tr>
    <th>Event Name</th>
    <th>Venue Name</th>
    <th>Event City</th>
    <th>Event Date</th>
    <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    {organizedEvents.map((event, index) => {
      const eventDate = new Date(event.EventDate);
      const formattedDate = `${eventDate.toLocaleDateString('en-US')} ${eventDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`;
      
      return (
        <tr key={event.id}>
        <td>{event.EventName}</td>
        <td>{event.VenueName}</td>
        <td>{event.EventCity}</td>
        <td>{formattedDate}</td>
        <td>
        <div className="d-flex gap-2">
        <Button variant="outline-success" onClick={() => handleEditEvent(event)}>
        <i className="fas fa-edit"></i> Edit
        </Button>
        <Button variant="outline-danger" onClick={() => handleDeleteEvent(event.EventID)}>
        <i className="fas fa-trash"></i> Delete
        </Button>
        <Button variant="outline-info" onClick={() => fetchPurchasedTickets(event.EventID)}>
        <i className="fas fa-ticket-alt"></i> View Purchases
        </Button>
        <Button variant="outline-primary" onClick={() => handleSendComplimentaryTicket(event.EventID)}>
        <i className="fas fa-gift"></i> Send Complimentary Ticket
        </Button>
        </div>
        </td>
        </tr>
      );
    })}
    </tbody>
    </Table>
    
    <div className="mb-3 d-flex justify-content-between">
    <InputGroup className="w-50">
    <InputGroup.Text>Filter</InputGroup.Text>
    <FormControl
    placeholder="Search by name, email, or phone"
    onChange={handleFilterChange}
    />
    </InputGroup>
    <div className="d-flex gap-2">
    <Button variant="outline-secondary" onClick={printTickets}>
    <i className="fas fa-print"></i> Print
    </Button>
    <Button variant="outline-success" onClick={downloadAsCSV}>
    <i className="fas fa-file-csv"></i> Download CSV
    </Button>
    <Button variant="outline-primary" onClick={downloadAsExcel}>
    <i className="fas fa-file-excel"></i> Download Excel
    </Button>
    </div>
    </div>
    
    {/* Summary Section with Card Layout */}
    <Row className="summary mb-4">
    <Col md={4}>
    <Card className="text-center p-3">
    <Card.Body>
    <Card.Title>Total Purchases</Card.Title>
    <Card.Text className="display-6">{summary.totalPurchases}</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    <Col md={4}>
    <Card className="text-center p-3">
    <Card.Body>
    <Card.Title>Total Tickets Sold</Card.Title>
    <Card.Text className="display-6">{summary.totalTicketsSold}</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    <Col md={4}>
    <Card className="text-center p-3">
    <Card.Body>
    <Card.Title>Total Amount Paid</Card.Title>
    <Card.Text className="display-6">CA$ {summary.totalAmountPaid}</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    <Col md={4}>
    <Card className="text-center p-3 mt-3">
    <Card.Body>
    <Card.Title>Average Ticket Price</Card.Title>
    <Card.Text className="display-6">CA$ {summary.averageTicketPrice}</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    <Col md={4}>
    <Card className="text-center p-3 mt-3">
    <Card.Body>
    <Card.Title>Most Popular Ticket Type</Card.Title>
    <Card.Text className="display-6">{summary.mostPopularType}</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    <Col md={4}>
    <Card className="text-center p-3 mt-3">
    <Card.Body>
    <Card.Title>Percentage Sold</Card.Title>
    <Card.Text className="display-6">{summary.percentageSold}%</Card.Text>
    </Card.Body>
    </Card>
    </Col>
    </Row>
    
    {loading ? (
      <p>Loading...</p>
    ) : error ? (
      <p className="text-danger">{error}</p>
    ) : purchasedTickets.length > 0 ? (
      <Table striped bordered>
      <thead>
      <tr>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Email</th>
      <th>Phone Number</th>
      <th>Ticket Type</th>
      <th>Quantity</th>
      <th>Total Amount</th>
      </tr>
      </thead>
      <tbody>
      {filteredTickets.map((ticket, index) => (
        <tr key={index}>
        <td>{ticket.FirstName}</td>
        <td>{ticket.LastName}</td>
        <td>{ticket.Email}</td>
        <td>{ticket.PhoneNumber}</td>
        <td>{ticket.TicketType}</td>
        <td>{ticket.Quantity}</td>
        <td>{ticket.TotalAmount}</td>
        </tr>
      ))}
      </tbody>
      </Table>
    ) : (
      <p>No purchased tickets found for this event.</p>
    )}
    </div>
  );
};

export default OrganizedEventsTable;