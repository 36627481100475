// General addmission

import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { fetchWithAuth } from '../react-auth-app/auth';
import { API_ENDPOINTS } from '../config';
import { calculateConvenienceFee } from '../utils/convenienceFeeCalculator';
import { calculateProcessingFee } from '../utils/processingFeeCalculator';
import { calculateSalesTax } from '../utils/taxCalculator';
import { calculateTotal } from '../utils/totalCalculator';

const GeneralAdmissionPurchase = ({ event }) => {
  const navigate = useNavigate();
  const [ticketOptions, setTicketOptions] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [salesTax, setSalesTax] = useState(0);
  const [totalWithFees, setTotalWithFees] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  // Fetch ticket data callback
  const fetchTicketData = useCallback(async (eventId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.getEventTickets(eventId));
      if (response.ok) {
        const data = await response.json();
        if (data && data.tickets) {
          setTicketOptions(data.tickets);
          //console.log('data:',data)
        }
      } else {
        console.error(`Failed to fetch tickets. Status code: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching event tickets:', error);
    }
    
  }, []);

  // Fetch ticket data when the component loads
  useEffect(() => {
    if (event && event.EventID) {
      fetchTicketData(event.EventID);
    } else {
      console.warn('Event or event.EventID is missing.');
    }
  }, [event, fetchTicketData]);

  // Calculate the total amount, fees, and total with tax
  useEffect(() => {
    // Calculate the subtotal for selected tickets
    const subtotal = Object.values(selectedTickets).reduce((acc, { price, quantity }) => acc + price * quantity, 0);
    
    // Initialize fees
    let calculatedConvenienceFee = 0;
    let calculatedProcessingFee = 0;
    
    // Determine if any ticket absorbs the service fee
    const anyTicketAbsorbsFee = Object.keys(selectedTickets).some(ticketId => {
      const selectedTicket = ticketOptions.find(ticket => ticket.TicketID === parseInt(ticketId, 10));
      return selectedTicket && selectedTicket.AbsorbServiceFee === 1;
    });
    
    if (!anyTicketAbsorbsFee) {
      // If none of the tickets absorb service fee, calculate fees normally
      calculatedConvenienceFee = calculateConvenienceFee(subtotal);
      calculatedProcessingFee = calculateProcessingFee(subtotal);
    }
    
    // Calculate sales tax based on whether fees are absorbed or not
    const calculatedSalesTax = calculateSalesTax(subtotal + calculatedConvenienceFee + calculatedProcessingFee);
    
    // Calculate the total with or without fees
    const totalWithTax = calculateTotal(subtotal + calculatedConvenienceFee + calculatedProcessingFee);
    
    // Update state
    setTotalAmount(subtotal);
    setConvenienceFee(calculatedConvenienceFee);
    setProcessingFee(calculatedProcessingFee);
    setSalesTax(calculatedSalesTax);
    setTotalWithFees(totalWithTax);
  }, [selectedTickets, ticketOptions]);


  // updated code
  const handleTicketChange = (ticketId, price, quantity) => {
    const selectedTicket = ticketOptions.find(ticket => ticket.TicketID === ticketId);
    setSelectedTickets((prevTickets) => ({
      ...prevTickets,
      [ticketId]: {
        price,
        quantity: parseInt(quantity, 10),
        ticketType: selectedTicket.TicketType,
        Notes: selectedTicket.Notes,
        details: selectedTicket.TicketDetails
      },
    }));
  };

  const handlePurchase = () => {
    if (Object.keys(selectedTickets).length === 0 || totalAmount === 0) {
      setShowAlert(true); // Show alert if no tickets are selected
    } else {
      // Proceed with purchase
      navigate('/Checkout', {
        state: { event, selectedTickets, totalWithFees, totalAmount },
      });
    }
  };
  
  //console.log('fetchTicketData:',fetchTicketData)
  
  return (
    <Container className="mt-3">
      <h5 className="text-left MB-3">Please select at least one ticket type to proceed.</h5>
      {showAlert && <Alert variant="danger">Please select at least one ticket type to proceed.</Alert>}

      {/* Render ticket details */}
      {ticketOptions.length > 0 && (
        <div className="mb-3 p-3 shadow-lg">
        <ul className="list-group list-group-flush" style={{ backgroundColor: "#e0e4e5", borderRadius: "12px" }}>
        {ticketOptions.map((ticket) => (
          <li key={ticket.TicketID} className="list-group-item" style={{ backgroundColor: "#f5f5f5", borderRadius: "10px" }}>
          <strong>{ticket.TicketType}</strong> - ${ticket.Price}
          {/* Ticket notes on the second line */}
          <div className="text-info mt-0">- {ticket.TicketDetails}</div>
          <div className="text-info mt-0">- {ticket.Notes}</div>
          </li>
        ))}
        </ul>
        </div>
      )}
    
    <Form>
    {ticketOptions.map((ticket) => (
      <Form.Group key={ticket.TicketID} controlId={`ticket-${ticket.TicketID}`}>
      <Form.Label>{ticket.TicketType} - ${ticket.Price} - {ticket.Availability} available</Form.Label>
      <Form.Control
      type="number"
      min="0"
      max={ticket.Availability}
      placeholder="Enter quantity"
      onChange={(e) => handleTicketChange(ticket.TicketID, ticket.Price, e.target.value || 0)}
      />
      </Form.Group>
    ))}
    
    <div className="mt-3">
    <p className="mb-1"><strong>Subtotal:</strong> ${totalAmount > 0 ? totalAmount.toFixed(2) : '0.00'}</p>
    
    {/* Show fees and taxes only if a ticket quantity has been entered */}
    {totalAmount > 0 && (
      <>
      {!Object.keys(selectedTickets).some(ticketId => {
        const selectedTicket = ticketOptions.find(ticket => ticket.TicketID === parseInt(ticketId, 10));
        return selectedTicket && selectedTicket.AbsorbServiceFee === 1;
      }) && (
        <>
        <p className="mb-1"><strong>Convenience Fee:</strong> ${convenienceFee > 0 ? convenienceFee.toFixed(2) : '0.00'}</p>
        <p className="mb-1"><strong>Processing Fee:</strong> ${processingFee > 0 ? processingFee.toFixed(2) : '0.00'}</p>
        </>
      )}
      
      <p className="mb-1"><strong>Sales Tax (13% HST):</strong> ${salesTax > 0 ? salesTax.toFixed(2) : '0.00'}</p>
      <p className="mb-1"><strong>Total (Tax & Fees included):</strong> ${totalWithFees > 0 ? totalWithFees.toFixed(2) : '0.00'}</p>
      </>
    )}
    
    {totalAmount === 0 && (
      <small className="d-block">Please select a ticket to proceed with fees and taxes calculation.</small>
    )}
    </div>
    
    <Row className="mt-3">
    <Button variant="primary" onClick={handlePurchase} disabled={totalAmount === 0}>
    Proceed to Purchase
    </Button>
    </Row>
    </Form>
    
    </Container>
  );
};

export default GeneralAdmissionPurchase;