// TicketPurchasePage.js

import React from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import GeneralAdmissionPurchase from './GeneralAdmissionPurchase'; // Import the General Admission Purchase component
import ConcertPurchaseSettings from './ConcertPurchaseSettings'; // Import the General Admission Purchase component

const TicketPurchasePage = () => {
  const navigate = useNavigate();
  const location = useLocation();  
  const event = location.state?.event || {};  // Provide fallback, Retrieve event from location state
    
  if (!event) {
    return <div>Event details are not available. Please try again.</div>;
  }
  
  // Define purchase settings based on event type, normalizing to lowercase
  const renderPurchaseSettings = () => {
    const normalizedEventType = event.EventType.toLowerCase();  // Convert to lowercase
    
    switch (normalizedEventType) {
      case 'general admission':
        return <GeneralAdmissionPurchase event={event} />; // Show general admission purchase settings
      case 'banquet hall':
        return <p>Purchase settings for Banquet Hall events include selecting table and meal preferences.</p>;
      case 'conference':
        return <p>Purchase settings for Conferences include full-access passes and session selections.</p>;
      case 'festival':
        return <p>Purchase settings for Festivals include day passes and VIP options.</p>;
      case 'seminar':
        return <p>Purchase settings for Seminars include single-day or multi-day tickets.</p>;
      case 'workshop':
        return <p>Purchase settings for Workshops include materials fees and registration options.</p>;
      case 'concert':  
        //return <p>Purchase settings for Concerts include seat selection and VIP backstage access.</p>;
        return <ConcertPurchaseSettings event={event} />; // Show general admission purchase settings
      case 'exhibition':
        return <p>Purchase settings for Exhibitions include general admission and guided tour options.</p>;
      case 'trade show':
        return <p>Purchase settings for Trade Shows include exhibitor booth registration and general tickets.</p>;
      case 'networking event':
        return <p>Purchase settings for Networking Events include early bird tickets and session access.</p>;
      case 'charity event':
        return <p>Purchase settings for Charity Events include donation options and VIP dinner tickets.</p>;
      case 'product launch':
        return <p>Purchase settings for Product Launches include exclusive pre-order tickets.</p>;
      case 'award ceremony':
        return <p>Purchase settings for Award Ceremonies include seat reservations and after-party access.</p>;
      case 'wedding':
        return <p>Purchase settings for Weddings include RSVP tickets for ceremony and reception.</p>;
      case 'birthday party':
        return <p>Purchase settings for Birthday Parties include RSVP and guest seating options.</p>;
      case 'anniversary celebration':
        return <p>Purchase settings for Anniversary Celebrations include RSVP and dinner selections.</p>;
      case 'fundraising event':
        return <p>Purchase settings for Fundraising Events include donation tiers and event access options.</p>;
      case 'sports event':
        return <p>Purchase settings for Sports Events include seat selection and VIP access.</p>;
      default:
        return <p>Purchase settings are not available for this event type.</p>;
    }
  };
  
  return (
    <>
    <Row className="justify-content-center p-md-3 mx-auto bg-shadow">
    <Container className="p-2 p-md-3 mb-0 border rounded bg-light">
    <h1 className="text-center">Ticket Purchase</h1>
    <div className="bg-light-gray p-0 rounded">
    <div className="text mt-0"><strong>Event Name:</strong> {event.EventName}</div>
    <div className="text mt-0"><strong>Event Date:</strong> {new Date(event.EventDate).toLocaleDateString('en-US')}</div>
    <div className="text mt-0"><strong>Venue:</strong> {event.VenueName}, {event.VenueAddress}</div>
    <hr />
    {renderPurchaseSettings()}
    </div>
    </Container>
    </Row>
    </>
  );
};

export default TicketPurchasePage;
  