import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './bootstrap-custom.css';
import { API_ENDPOINTS } from '../config';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    zipCode: '',
    country: ''
  });

  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await fetch(API_ENDPOINTS.register, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Registration failed');
      }

      setSuccessMessage('User registered successfully');
      setFormData({
        username: '',
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        zipCode: '',
        country: ''
      });
    } catch (error) {
      setError(error.message || 'An error occurred while processing your request.');
    }
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row justify-content-center p-3">
        <div className="col-12 col-md-8 col-lg-6 border rounded border-gray p-3 d-flex flex-column flex-md-row align-items-center">
          {/* Image for larger screens */}
          <div className="me-3 d-none d-md-block flex-shrink-0" style={{ width: '45%', height: '100%' }}>
            <img src="images/photo-signup.jpeg" alt="Image-signup" className="img-fluid w-100 h-100" />
          </div>

          {/* Form content */}
          <div className="w-100">
            <h2>Register</h2>
            <h6 className="mb-4">
              Already registered? <Link to="/sign-in">Sign in</Link>
            </h6>
            {error && <div className="alert alert-danger">{error}</div>}
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">Username</label>
                <input type="text" className="form-control" id="username" name="username" value={formData.username} onChange={handleInputChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleInputChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleInputChange} />
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="firstname" className="form-label">First Name</label>
                  <input type="text" className="form-control" id="firstname" name="firstname" value={formData.firstname} onChange={handleInputChange} />
                </div>
                <div className="col">
                  <label htmlFor="lastName" className="form-label">Last Name</label>
                  <input type="text" className="form-control" id="lastName" name="lastname" value={formData.lastname} onChange={handleInputChange} />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="zipCode" className="form-label">Zip Code</label>
                  <input type="text" className="form-control" id="zipCode" name="zipCode" value={formData.zipCode} onChange={handleInputChange} />
                </div>
                <div className="col">
                  <label htmlFor="country" className="form-label">Country</label>
                  <select className="form-select" id="country" name="country" value={formData.country} onChange={handleInputChange}>
                    <option value="">Select Country</option>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                    <option value="UK">United Kingdom</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
              </div>
              <button type="submit" className="btn btn-primary">Register</button>
              <div className="mt-4 small text-muted">
                By continuing past this page, you agree to the Terms of Use and understand that information will be used as described in our Privacy Policy.
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;