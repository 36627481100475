// src/config.js
// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3010';

const mode = process.env.APP_MODE || 'production'; // Default to 'development' if not set
console.log(`Running in ${mode} mode`);

const API_URL = mode === 'development'
  ? process.env.REACT_APP_API_URL_TEST
  : process.env.REACT_APP_API_URL_LIVE;

  const MAILSERVER_API_URL = mode === 'development'
    ? process.env.REACT_APP_MAIL_SERVER_API_URL_TEST
    : process.env.REACT_APP_MAIL_SERVER_API_URL_LIVE;
    
console.log('API_URL:', API_URL);
console.log('MAILSERVER_API_URL:', MAILSERVER_API_URL);

export const API_ENDPOINTS = {
  API_URL: `${API_URL}`,
  MAILSERVER_API_URL: `${MAILSERVER_API_URL}`,
  sendMail: `${MAILSERVER_API_URL}/send-email`,
  eventSearch: `${API_URL}/eventsearch`,
  getUser: (userId) => `${API_URL}/user/${userId}`,
  register: `${API_URL}/register`,
  login: `${API_URL}/login`,
  events: `${API_URL}/events`,
  getEvent: (eventID) => `${API_URL}/events/${eventID}`,
  PLACEHOLDER: '/uploads/placeholder_image.png',
  getTickets: (userId) => `${API_URL}/user/${userId}/tickets`,
  getEventTickets: (eventId) => `${API_URL}/events/${eventId}/tickets`,
  organizedEvents: (userId) => `${API_URL}/user/${userId}/organized-events`,
  createEvent: `${API_URL}/create-event`,
  uploads: `${API_URL}/uploads`,
  stripePayment: `${API_URL}/create-payment-intent`,
  saveCustomerInfo: `${API_URL}/save-customer-info`,
  reduceTicketAvailability: `${API_URL}/reduce-ticket-availability`,
  getEventPurchasedTickets: (eventId) => `${API_URL}/events/${eventId}/purchased-tickets`,
  deleteEvent: (eventId) => `${API_URL}/events/${eventId}`
  // Add other endpoints here
};

// Export the mode variable
export { mode };