// src/utils/processingFeeCalculator.js

const PROCESSING_FEE_RATE = 0.03; // Assume 3% online processing fee rate
const PROCESSING_FEE_FIXED = 0.30; // Assume 3% online processing fee rate

// Function to calculate processing fee for a given amount
function calculateProcessingFee(amount) {
  return amount * PROCESSING_FEE_RATE + PROCESSING_FEE_FIXED;
}

// Export the functions
export { calculateProcessingFee };