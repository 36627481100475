// UserPanel.js
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchWithAuth } from '../react-auth-app/auth';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import CreateEvent from '../create-event/CreateEvent';
import UserInfo from '../react-auth-app/userInfo';
import TicketsTable from '../react-auth-app/TicketsTable';
import OrganizedEventsTable from '../react-auth-app/OrganizedEventsTable';
import { API_ENDPOINTS } from '../config';
import './UserPanel.css';

const UserPanel = () => {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [organizedEvents, setOrganizedEvents] = useState([]);
  const [userData, setUserData] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);
  const [showCreateEventForm, setShowCreateEventForm] = useState(false);
  const [showOrganizedEvents, setShowOrganizedEvents] = useState(false);

  const fetchUserData = useCallback(async (userId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.getUser(userId));
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      navigate('/sign-in');
    }
  }, [navigate]);

  const fetchTickets = useCallback(async (userId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.getTickets(userId));
      const data = await response.json();
      setTickets(data);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  }, []);

  const fetchOrganizedEvents = useCallback(async (userId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.organizedEvents(userId));
      const data = await response.json();
      setOrganizedEvents(data);
    } catch (error) {
      console.error('Error fetching organized events:', error);
    }
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');

    if (userId && accessToken) {
      (async () => {
        try {
          await fetchUserData(userId);
          if (userData) {
            await fetchTickets(userData.id);
            await fetchOrganizedEvents(userData.id);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      })();
    } else {
      navigate('/sign-in');
    }
  }, [fetchUserData, fetchTickets, fetchOrganizedEvents, navigate, userData]);

  const handleCreateEvent = () => {
    setShowCreateEventForm(true);
    setShowOrganizedEvents(false);
    setEditingEvent(null);
  };

  const handleShowPurchases = () => {
    setShowCreateEventForm(false);
    setShowOrganizedEvents(false);
  };

  const handleShowOrganizedEvents = () => {
    setShowCreateEventForm(false);
    setShowOrganizedEvents(true);
  };

  const handleEditEvent = (event) => {
    setEditingEvent(event); // Set the selected event as the event to edit
    setShowCreateEventForm(true); // Show the form for editing
  };

  const handleDeleteEvent = async (eventId) => {
    //console.log('eventID is:',event.EventID)
    //console.log('API_ENDPOINTS.deleteEvent(eventId) is:',API_ENDPOINTS.deleteEvent(eventId))
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        const response = await fetch(API_ENDPOINTS.deleteEvent(eventId), {
          method: 'DELETE',
        });
        if (response.ok) {
          alert('Event deleted successfully!');
          if (userData) fetchOrganizedEvents(userData.id);
        } else {
          console.error('Failed to delete event.');
        }
      } catch (error) {
        console.error('Error deleting event:', error);
      }
    }
  };

  const handleSignOut = () => {
    setUserData(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    document.cookie = 'refreshToken=; Max-Age=0; path=/; domain=' + window.location.hostname;
    navigate('/sign-in');
  };

  const handleEventCreated = () => {
    if (userData) fetchOrganizedEvents(userData.id);
    setShowCreateEventForm(false);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className="shadow-lg p-2">
            <Card.Body>
              <h2 className="card-title text-center">User Panel</h2>
              {userData && <UserInfo userData={userData} />}
              <div className="row mb-3 button-group">
                <div className="col">
                  <Button variant="outline-primary" className="w-100 button-equal-height" onClick={handleCreateEvent}>Create Event</Button>
                </div>
                <div className="col">
                  <Button variant="outline-info" className="w-100 button-equal-height" onClick={handleShowPurchases}>Show Purchases</Button>
                </div>
                <div className="col">
                  <Button variant="outline-dark" className="w-100 button-equal-height" onClick={handleShowOrganizedEvents}>Organized Events</Button>
                </div>
                <div className="col">
                  <Button variant="outline-warning" className="w-100 button-equal-height" onClick={handleSignOut}>Sign Out</Button>
                </div>
              </div>
              {showCreateEventForm ? (
                <CreateEvent userData={userData} onEventCreated={handleEventCreated} editingEvent={editingEvent} />
              ) : showOrganizedEvents ? (
                <OrganizedEventsTable organizedEvents={organizedEvents} handleEditEvent={handleEditEvent} handleDeleteEvent={handleDeleteEvent} />
              ) : (
                <TicketsTable tickets={tickets} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserPanel;