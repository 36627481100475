import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import { useLocation,useNavigate  } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { API_ENDPOINTS } from '../config';

const OrderConfirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { event, selectedTickets, totalWithFees, buyerInfo, paymentReference } = location.state || {};
    
    const [emailSent, setEmailSent] = useState(() => sessionStorage.getItem('emailSent') === 'true');
    const [ticketReduction, setTicketReduction] = useState(() => sessionStorage.getItem('ticketReduction') === 'true');
    const [customerInfoSaved, setCustomerInfoSaved] = useState(() => sessionStorage.getItem('customerInfoSaved') === 'true');
    const [emailError, setEmailError] = useState(null);
    const [timeLeft, setTimeLeft] = useState(0);

    // time logic
    useEffect(() => {
        const now = Date.now();
        let expirationTime = localStorage.getItem('confirmationExpirationTime');
        
        if (!expirationTime) {
        // Set expiration time to 10 minutes from now if not already set
        expirationTime = now + 10 * 60 * 1000;
        localStorage.setItem('confirmationExpirationTime', expirationTime);
        }
        
        const timerInterval = setInterval(() => {
        const currentTime = Date.now();
        const remainingTime = Math.max((expirationTime - currentTime) / 1000, 0);
        setTimeLeft(remainingTime);
        
        if (remainingTime <= 0) {
        clearInterval(timerInterval);
        localStorage.removeItem('confirmationExpirationTime'); // Clear the stored time
        navigate('/'); // Redirect to home page
        }
        }, 1000);
        
        // Clear the interval and local storage on unmount
        return () => {
        clearInterval(timerInterval);
        localStorage.removeItem('confirmationExpirationTime');
        };
    }, [navigate]);
    
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    
    // Function to send the email
    const sendEmail = useCallback(async () => {
        if (emailSent) return;
        
        try {
        
            // Extracting details from selectedTickets
            const type = Object.values(selectedTickets).map(details => details.ticketType).join(', ');
            const quantity = Object.values(selectedTickets).map(details => details.quantity).join(', ');
            const price = Object.values(selectedTickets).map(details => details.price).join(', ');

            const reservationData = {
            buyerName: `${buyerInfo?.firstName} ${buyerInfo?.lastName}`,
            buyerEmail: `${buyerInfo?.email}`,
            eventName: `${event?.EventName}`,
            eventOrganizer: `${event?.OrganizerName}`,
            eventDate: new Date(event?.EventDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }),
            eventTime: `${event?.StartTime} - ${event?.EndTime}`,
            DoorsOpenTime: `${event?.DoorsOpenTime}`,
            venue: `${event?.VenueName}, ${event?.VenueAddress}, ${event?.EventCity}`,

            // Passing the individual variables
            type, // e.g., "VIP, Standard"
            quantity, // e.g., "2, 4"
            price, // e.g., "100, 50"

            totalAmount: totalWithFees,
        };

        //console.log('mail-server:',`${API_ENDPOINTS.MAILSERVER_API_URL}`)
        //console.log('mail-server-full:',`${API_ENDPOINTS.sendMail}`)
        
        const response = await fetch(`${API_ENDPOINTS.sendMail}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ reservationData }),
            });
            
            if (response.ok) {
            setEmailSent(true);
            sessionStorage.setItem('emailSent', 'true');
            } else {
            throw new Error('Failed to send email');
            }
            } catch (error) {
            setEmailError('Failed to send confirmation email. Please contact info@eventtop.ca for assistance.');
            console.error('Error sending email:', error);
            }
        }, [emailSent, API_ENDPOINTS.MAILSERVER_API_URL, buyerInfo, event, selectedTickets, totalWithFees]);
    
    // Function to save customer info
    const saveCustomerInfo = useCallback(async () => {
    if (customerInfoSaved) return;
    
        const customerData = {
        eventID: event.EventID,
        firstName: buyerInfo.firstName,
        lastName: buyerInfo.lastName,
        email: buyerInfo.email,
        phone: buyerInfo.phone,
        address: buyerInfo.address,
        ticketType: Object.keys(selectedTickets).map(ticketId => selectedTickets[ticketId].ticketType).join(', '),
        quantity: Object.values(selectedTickets).reduce((acc, ticket) => acc + ticket.quantity, 0),
        totalAmount: totalWithFees,
        convenienceFee: 0,
        processingFee: 0,
        salesTax: 0,
        paymentReference: paymentReference || 'N/A',
    };

    try {
        const response = await fetch(`${API_ENDPOINTS.saveCustomerInfo}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(customerData),
    });
    
    if (response.ok) {
    setCustomerInfoSaved(true);
    sessionStorage.setItem('customerInfoSaved', 'true');
    console.log('Customer information saved successfully');
    } else if (response.status === 409) {
    console.log('Customer information already saved, skipping...');
    } else {
    console.error('Failed to save customer information');
    }
    } catch (error) {
    console.error('Error saving customer info:', error);
    }
    }, [customerInfoSaved, API_ENDPOINTS.saveCustomerInfo, event, buyerInfo, selectedTickets, totalWithFees, paymentReference]);

// Function to reduce ticket availability
const reduceTicketAvailability = useCallback(async () => {
if (ticketReduction) return;

try {
const updateData = {
eventID: event.EventID,
tickets: Object.entries(selectedTickets).map(([ticketId, details]) => ({
ticketID: ticketId,
quantity: details.quantity,
})),
};

const response = await fetch(`${API_ENDPOINTS.reduceTicketAvailability}`, {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
body: JSON.stringify(updateData),
});

if (response.ok) {
setTicketReduction(true);
sessionStorage.setItem('ticketReduction', 'true');
console.log('Ticket availability updated successfully');
} else {
console.error('Failed to update ticket availability');
}
} catch (error) {
console.error('Error updating ticket availability:', error);
}
}, [ticketReduction, API_ENDPOINTS.reduceTicketAvailability, event, selectedTickets]);

// useEffect hook
useEffect(() => {
if (paymentReference && !customerInfoSaved) {
saveCustomerInfo();
}
}, [paymentReference, customerInfoSaved, saveCustomerInfo]);

useEffect(() => {
if (paymentReference && !ticketReduction) {
reduceTicketAvailability();
}
}, [paymentReference, ticketReduction, reduceTicketAvailability]);

useEffect(() => {
if (paymentReference && !emailSent) {
sendEmail();
}
}, [paymentReference, emailSent, sendEmail]);

return (
<Container className="mt-5">
    <div className="p-4 bg-light shadow-lg rounded" style={{ backgroundColor: '#f9f9f9', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' }}>
        <h2 className="text-center mb-4">Thank You for Your Purchase!</h2>

        {/* Display warning if email sending failed */}
        {emailError && (
        <Alert variant="warning" className="text-center">
            <strong>Warning:</strong> We were unable to send your confirmation email. Please contact 
            <a href="mailto:info@eventtop.ca"> info@eventtop.ca</a> to receive a copy of your ticket.
        </Alert>
        )}

        <Row>
            {/* Left column: Customer, Event, and ticket details */}
            <Col md={8}>
                <h4>Customer Information</h4>
                <div className="text mt-0"><strong>Name:</strong> {buyerInfo?.firstName} {buyerInfo?.lastName}</div>
                <div className="text mt-0"><strong>Email:</strong> {buyerInfo?.email}</div>
                <div className="text mt-0"><strong>Phone:</strong> {buyerInfo?.phone}</div>
                <div className="text mt-0 mb-2"><strong>Address:</strong> {buyerInfo?.address}</div>
                
                {event && (
                <>
                <h4 className="mt-3">Event Information</h4>
                <div className="text mt-0"><strong>Event Name:</strong> {event.EventName}</div>
                <div className="text mt-0"><strong>Organizer:</strong> {event.OrganizerName}</div>
                <p className="mb-0"><strong>Date/Time:</strong> {new Date(event.EventDate).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    })}, {event.StartTime} - {event.EndTime}</p>
                <p className="mb-0"><strong>Doors Open:</strong> {event.DoorsOpenTime}</p>
                <div className="text mt-0"><strong>Venue:</strong> {event.VenueName}, {event.VenueAddress}, {event.EventCity}</div>
                </>
                )}
                
                <h4 className="mt-3">Selected Tickets</h4>
                
                <ul className="list-group mb-3 shadow-sm">
                    {selectedTickets && Object.entries(selectedTickets).map(([ticketId, ticketDetails]) => (
                    <li key={ticketId} className="list-group-item">
                        <strong>Ticket:</strong> {ticketDetails.ticketType} | 
                        <strong> Quantity:</strong> {ticketDetails.quantity} | 
                        <strong> Price:</strong> ${ticketDetails.price} + HST
                        
                        {/* Notes and Details specific to each ticket */}
                        {ticketDetails.Notes && ( 
                        <div className="mt-0">
                            <p className="mb-0"><strong>Notes:</strong> {ticketDetails.Notes}</p>
                        </div>
                        )}
                        {ticketDetails.details && (
                        <div className="mt-0">
                            <p className="mb-0"><strong>Details:</strong> {ticketDetails.details}</p>
                        </div>
                        )}
                        
                    </li>
                    ))}
                </ul>
                
                
                <h4 className="mt-3"><strong>Total Paid:</strong> CA${totalWithFees.toFixed(2)}</h4>
            </Col>
            
            {/* Right column: Event poster */}
            <Col md={4}>
                <Card className="shadow-sm">
                    {event?.PosterImagePath ? (
                    <Card.Img
                        variant="top"
                        src={`${API_ENDPOINTS.API_URL}${event.PosterImagePath}`}
                        alt="Event Poster"
                    />
                    ) : (
                    <Card.Img
                        variant="top"
                        src="https://via.placeholder.com/300x400.png?text=Event+Poster"
                        alt="Default Poster"
                    />
                    )}
                    <Card.Body>
                        <Card.Title>{event.EventName}</Card.Title>
                        <Card.Text>{event.Theme}</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        
        
        <div className="alert alert-warning mb-0" role="alert">
            <strong>Note</strong>: All tickets are final sale and <strong>non-refundable</strong>. For any inquiries, please contact the organizer.
        </div>
        
        
        <hr />
        
        <div className="mt-4 row">
            {/* Left column: Venue Information */}
            <div className="col-md-8">
                <h4 className="text-left">Venue Information:</h4>
                <ul className="list-group">
                    <li className="list-group-item">
                        <strong>Seated Ticket Holders:</strong> You can:
                        <ul className="ms-3">
                            <li>
                                Reserve a table at <a href="https://lula.ca/reservations" target="_blank" rel="noopener noreferrer" className="text-decoration-underline">lula.ca/reservations</a> or call <a href="tel:4165880307" className="text-decoration-none">416-588-0307</a>.
                            </li>
                            <li>
                                Check out the Lula dinner menu at <a href="https://lula.ca/menu" target="_blank" rel="noopener noreferrer">lula.ca/menu</a>.
                            </li>
                            <li>
                                Doors open for dinner and drinks: {event.DoorsOpenTime} / Show: {event.StartTime}.
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            
            {/* Right column: Barcode Image */}
            <div className="col-md-4 mt-4 d-flex align-items-center justify-content-center">

            <div 
                className="d-flex" 
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/Eventtop-barcode.jpg)`,
                    backgroundSize: 'contain', // Use 'contain' to show the full image
                    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
                    backgroundPosition: 'center', // Align the image to the left
                    height: '150px', // Adjust the height as necessary
                    width: '100%', // Make the div full width within its container
                    maxWidth: '300px' // Limit the maximum width for responsiveness
                    }}
            >
            </div>
                
            </div>

            

            
        </div>
        
        <hr />
        
        <Row className="mt-4">
            <h4 className="text-center">Share Your Experience</h4>
            
            <div className="d-flex justify-content-center flex-wrap">
                <a
                    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent('https://eventtop.ca')}&text=${encodeURIComponent(`🎉 Just had an amazing experience booking tickets for ${event.EventName} at EventTop! 🌟 Get yours now at EventTop.ca 🎟️ #EventTop #BestExperience #TicketBooking`)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary d-flex align-items-center justify-content-center m-1 social-btn w-50 w-md-auto"
                    style={{ flex: '1 1 auto', maxWidth: '200px' }}
                >
                    <FaTwitter className="social-icon" />
                    <span className="d-none d-md-inline ms-2">Share on X</span>
                </a>
                
                <a
                    href={`https://www.instagram.com/eventtop.ca`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-danger d-flex align-items-center justify-content-center m-1 social-btn w-50 w-md-auto"
                    style={{ flex: '1 1 auto', maxWidth: '200px' }}
                >
                    <FaInstagram className="social-icon" />
                    <span className="d-none d-md-inline ms-2">Share on Instagram</span>
                </a>
                
                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://eventtop.ca')}&quote=${encodeURIComponent(`🎉 Just had an amazing experience booking tickets for ${event.EventName} at EventTop! 🌟 Get yours now at EventTop.ca 🎟️ #EventTop #BestExperience #TicketBooking`)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary d-flex align-items-center justify-content-center m-1 social-btn w-50 w-md-auto"
                    style={{ flex: '1 1 auto', maxWidth: '200px' }}
                >
                    <FaFacebook className="social-icon" />
                    <span className="d-none d-md-inline ms-2">Share on Facebook</span>
                </a>
            </div>
        </Row>
        
    </div>
</Container>
);

};

export default OrderConfirmation;