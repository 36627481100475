import React, { useState, useEffect } from 'react';
import { Card, Button, Col, Row, Pagination } from 'react-bootstrap';
import './EventList.css';
import { API_ENDPOINTS } from '../config';
import { FaMapMarkerAlt, FaCalendarAlt, FaMusic, FaTheaterMasks, FaUserFriends } from 'react-icons/fa';
import PromoBanner from './PromoBanner';

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 6;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.events);
        const data = await response.json();
        const sortedEvents = data.sort((a, b) => new Date(b.EventDate) - new Date(a.EventDate));
        setEvents(sortedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, []);

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);
  const totalPages = Math.ceil(events.length / eventsPerPage);

  const handleBuyTickets = (eventID) => {
    window.location.href = `/ticket-store?eventID=${eventID}`;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container-fluid p-3 mx-auto flex-column responsive-container">
      <h2 className="mt-4">Upcoming Events:</h2>
      <Row>
        {currentEvents.map((event) => {
          const imagePath = event.PosterImagePath
            ? `${API_ENDPOINTS.uploads}/${event.PosterImagePath.split('/').pop()}`
            : `${API_ENDPOINTS.uploads}/placeholder_image.png`;

          const formattedDate = new Date(event.EventDate).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          });

          return (
            <Col md={6} lg={4} key={event.EventID} className="mb-3">
              <Card className="shadow-sm event-card">
                <div className="card-header bg-primary text-white">
                  <h4 className="mb-0 truncated-text">{event.EventName}</h4>
                </div>
                <img
                  src={imagePath}
                  alt="Event Poster"
                  className="event-poster"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = `${API_ENDPOINTS.uploads}/placeholder_image.png`;
                  }}
                />
                <Card.Body className="p-3 card-body-content">
                  <Card.Text className="mb-2 truncated-text">
                    <FaCalendarAlt size={20} className="me-2" />
                    {formattedDate} | {event.StartTime} - {event.EndTime}
                  </Card.Text>
                  <Card.Text className="mb-2 truncated-text">
                    <FaMapMarkerAlt size={20} className="me-2" />
                    {event.VenueName}, {event.EventCity}, {event.EventCountry}
                  </Card.Text>
                  <Card.Text className="mb-2 truncated-text">
                    <FaUserFriends size={20} className="me-2" />
                    Organizer: {event.OrganizerName}
                  </Card.Text>
                  <Card.Text className="mb-2 truncated-text">
                    {event.Theme.toLowerCase().includes('music') ? <FaMusic size={20} /> : <FaTheaterMasks size={20} />} Theme: {event.Theme}
                  </Card.Text>
                  <Button variant="primary" className="mt-auto" onClick={() => handleBuyTickets(event.EventID)}>
                    Get Information / Buy Tickets
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Pagination className="mt-4 d-flex justify-content-center">
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
      
      {/* Promotional Banner with Consistent Styling */}
      {/* Promotional Banner with Consistent Styling */}
      
      <PromoBanner />


      {/* Additional Section for Events in Toronto with Consistent Styling */}
      {/* Additional Section for Events in Toronto with Consistent Styling */}
      <h2 className="mt-4">Events in Toronto</h2>
      <Row>
        {currentEvents
          .filter(event => event.EventCity && event.EventCity.toLowerCase() === 'toronto')
          .slice(0, 2)
          .map(event => {
            const imagePath = event.PosterImagePath
              ? `${API_ENDPOINTS.uploads}/${event.PosterImagePath.split('/').pop()}`
              : `${API_ENDPOINTS.uploads}/placeholder_image.png`;

            const formattedDate = new Date(event.EventDate).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            });

            return (
              <Col md={6} lg={4} key={event.EventID} className="mb-3">
                <Card className="shadow-sm event-card">
                  <div className="card-header bg-info text-white">
                    <h4 className="mb-0 truncated-text">{event.EventName}</h4>
                  </div>
                  <img
                    src={imagePath}
                    alt="Event Poster"
                    className="event-poster"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `${API_ENDPOINTS.uploads}/placeholder_image.png`;
                    }}
                  />
                  <Card.Body className="p-3 card-body-content">
                    <Card.Text className="mb-2 truncated-text">
                      <FaCalendarAlt size={20} className="me-2" />
                      {formattedDate} | {event.StartTime} - {event.EndTime}
                    </Card.Text>
                    <Card.Text className="mb-2 truncated-text">
                      <FaMapMarkerAlt size={20} className="me-2" />
                      {event.VenueName}, {event.EventCity}, {event.EventCountry}
                    </Card.Text>
                    <Card.Text className="mb-2 truncated-text">
                      <FaUserFriends size={20} className="me-2" />
                      Organizer: {event.OrganizerName}
                    </Card.Text>
                    <Card.Text className="mb-2 truncated-text">
                      {event.Theme.toLowerCase().includes('music') ? <FaMusic size={20} /> : <FaTheaterMasks size={20} />} Theme: {event.Theme}
                    </Card.Text>
                    <Button variant="info" className="mt-auto" onClick={() => handleBuyTickets(event.EventID)}>
                      Buy Tickets
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default EventList;