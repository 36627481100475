import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './ReactHeader.css'; // Ensure you have this CSS file for custom styling

const ReactHeader = () => {

  const FixedHeaderImage = [
    'images/header-background-2.png',
  ];
  
  const artistImages = [
    'images/artist0.png',
    'images/artist1.png',
    'images/artist2.png',
    'images/artist3.png',
    'images/artist4.png',
    'images/artist5.png',
    'images/artist6.png',
    'images/artist7.png',
    'images/artist8.png',
    'images/artist9.png',
    'images/artist10.png',
  ];

  const artistCaptions = [
    'Tabriz and Canadian Turkish Ensemble',
    'Kazim Boran',
    'Bulent Goktas',
    'Golden Ozturk',
    'Gonay Akca',
    'Polad Kashef',
    'Roya Shamsazar',
    'Nasrin Attari',
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    if (!isSmallScreen) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % artistImages.length);
      }, 5000); // Change image every 5 seconds

      return () => clearInterval(interval);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreen, artistImages.length]);

  return (
    <main className="container-fluid p-0">
      <div
        className="p-1 p-md-5 text-light bg-body-secondary"
        style={{
          backgroundImage: `url(${isSmallScreen ? FixedHeaderImage[0] : artistImages[currentImageIndex]})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          height: '450px',
          width: '100%',
        }}
      >
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="px-2 my-4 text-left">
              {/* Use responsive text classes */}
              <h1 className={`display-4 display-md-2 display-lg-1 fw-bold fst-italic rounded text-shadow yeserva-font`}>
                Host your Events With Us
              </h1>
              <h3 className="text-md fs-2 fs-md-4 fs-lg-3 bounce text-shadow kavoon-font">
                <span className="top-text">Top Events,</span>
                <span className="tickets-text">Top Tickets,</span>
                <span className="with-text"> with </span>
                <span className="eventtop-text">EventTop!</span>
              </h3>
            </Col>
            
    {!isSmallScreen && (
      <div className="image-selectors p-1">
      {artistImages.map((_, index) => (
        <button
        key={index}
        className={`image-selector ${index === currentImageIndex ? 'active' : ''}`}
        onClick={() => setCurrentImageIndex(index)}
        ></button>
      ))}
      </div>
      
    )}

    <Link to="/EventHostingPage">
    <div className="text-start">
    <Button
    variant="btn btn-primary tickets-text fst-italic"
    className="mt-5"
    style={{
      height: '50px',
      border: '3px solid black',
      boxShadow: '2px 2px 8px rgba(0, 0, 1, 3.9)',
    }}
    >
    Organize Your Event
    </Button>
    </div>
    </Link>
    
          </Row>


        </Container>
      </div>
    </main>
  );
};

export default ReactHeader;